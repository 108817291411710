import NP from "number-precision";
import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    width: 240,
    fixed: "left",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("货品图片"),
    dataIndex: "material_image",
    width: 120,
    scopedSlots: { customRender: "material_image" },
  },
  {
    title: T("总数量"),
    dataIndex: "quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("在库位量"),
    dataIndex: "stock_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("不良品量"),
    dataIndex: "unqualified_quantity",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },

  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("单位"),
    dataIndex: "material_unit",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("单价"),
    dataIndex: "material_unit_price",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("总重量(吨)"),
    dataIndex: "material_weight",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_weight) {
        return NP.times(item.quantity, item.material_weight, 0.001);
      }
    },
  },
  {
    title: T("总体积(立方)"),
    dataIndex: "material_volume",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_volume) {
        return NP.times(item.quantity, item.material_volume);
      }
    },
  },
];
